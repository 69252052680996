/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import '../styles/index.scss'

import React, { Component } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Footer from './Footer'
import Header from './Header'
import PropTypes from 'prop-types'

const LayoutComponent = ({ breadcrumb, children, handleSlidePause, handleSlidePlay, isLoaded }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      wordpressSiteMetadata {
        name
      }
    }
  `)

  return (
    <>
      <Header
        breadcrumb={breadcrumb}
        handleSlidePause={handleSlidePause}
        handleSlidePlay={handleSlidePlay}
        siteTitle={data.wordpressSiteMetadata.name}
      />
      <main>{children}</main>
      <Footer />
    </>
  )
}

class Layout extends Component {
  constructor() {
    super()
    this._isMounted = false

    this.state = {
      isLoaded: typeof window !== `undefined` ? window.sessionStorage.getItem('initialized') === 'true' : false,
    }
  }

  componentDidMount() {
    this._isMounted = true

    const { isLoaded } = this.state

    if (typeof document !== `undefined`) {
      const Splitting = require('splitting')

      Splitting({
        target: '[data-splitting]',
        by: "chars",
        key: null,
      })
    }

    if (typeof window !== `undefined`) {
      require('lazysizes')
      require('lazysizes/plugins/native-loading/ls.native-loading')
    }

    if (!isLoaded) {
      if (typeof window !== `undefined`) {
        window.sessionStorage.setItem('initialized', 'true')
      }

      window.setTimeout(() => {
        if (this._isMounted) {
          this.setState({
            isLoaded: true,
          })
        }
      }, 15000)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    const { breadcrumb, children, className, handleSlidePause, handleSlidePlay } = this.props
    const { isLoaded } = this.state

    return (
      <div className={className}>
        <LayoutComponent
          breadcrumb={breadcrumb}
          children={children}
          isLoaded={isLoaded}
          handleSlidePause={handleSlidePause}
          handleSlidePlay={handleSlidePlay}
        />
      </div>

    )
  }
}

Layout.propTypes = {
  breadcrumb: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  handleSlidePause: PropTypes.func,
  handleSlidePlay: PropTypes.func,
}

Layout.defaultProps = {
  breadcrumb: false,
  className: 'page',
}

export default Layout
