import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import Breadcrumb from './Breadcrumb'
import Menu from './Menu'

import './Header.css'

const Header = ({ breadcrumb, handleSlidePause, handleSlidePlay, siteTitle }) => (
  <header className="header">
    <div className="container container--fluid">
      <div className="row">
        <div className="header__logo">
          <h1 className="header__title">
            <Link to="/">{siteTitle}</Link>
          </h1>
          {breadcrumb && <Breadcrumb breadcrumb={breadcrumb} />}
        </div>
        <div className="header__menu">
          <Menu handleSlidePause={handleSlidePause} handleSlidePlay={handleSlidePlay} />
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  breadcrumb: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  handleSlidePause: PropTypes.func,
  handleSlidePlay: PropTypes.func,
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  breadcrumb: false,
  siteTitle: ``,
}

export default Header
