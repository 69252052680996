import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import './Footer.css'

const pathFromUrl = url => {
  const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b/gm

  if (regex.test(url)) {
    return url.replace(regex, '')
  }

  return url
}

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        menus: wordpressMenusMenusItems(slug: {eq: "menu-secondaire"}) {
          items {
            db_id
            title
            url
          }
        }
      }
    `}
    render={data => (
      <footer className="footer">
        <div className="container container--fluid">
          <ul>
            {data.menus.items.map(item => (
              <li key={item.db_id}>
                <Link to={pathFromUrl(item.url)}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </footer>
    )}
  />
)

export default Footer
