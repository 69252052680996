import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'

const Breadcrumb = ({ breadcrumb }) => (
  <div className="breadcrumb">
    {
      breadcrumb.map((b, i) => (
        b.link ? (
          <Fragment key={i}>
            <TransitionLink
              to={b.link}
              exit={{
                length: b.exit,
              }}
              entry={{
                delay: b.entry,
              }}
              state={{
                index: b.index,
              }}
            >
              {b.label}
            </TransitionLink>
            <span className="breadcrumb__separator">»</span>
          </Fragment>
        ) : <span key={i}>{b.label}</span>
      ))
    }
  </div>
)

Breadcrumb.propTypes = {
  breadcrumb: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
}

export default Breadcrumb
