import React, { Component, createRef } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import './Menu.css'

class Menu extends Component {
  constructor() {
    super()

    this.menuRef = createRef()

    this.toggleMenu = this.toggleMenu.bind(this)
  }

  /**
   * Open or close the menu
   */
  toggleMenu() {
    const container = this.menuRef.current
    const button = container.querySelector('.menu__trigger')

    button.getAttribute('aria-expanded') === 'false' ? this.openMenu() : this.closeMenu()
  }

  /**
   * Open the menu
   */
  openMenu() {
    const { handleSlidePause } = this.props
    const container = this.menuRef.current
    const button = container.querySelector('.menu__trigger')
    const wrapper = container.querySelector('.menu__wrapper')

    button.setAttribute('aria-expanded', 'true')
    wrapper.setAttribute('aria-hidden', 'false')

    if (typeof handleSlidePause !== 'undefined') {
      handleSlidePause()
    }
  }

  /**
   * Close the menu
   */
  closeMenu() {
    const { handleSlidePlay } = this.props
    const container = this.menuRef.current
    const button = container.querySelector('.menu__trigger')
    const wrapper = container.querySelector('.menu__wrapper')

    button.setAttribute('aria-expanded', 'false')
    wrapper.setAttribute('aria-hidden', 'true')

    if (typeof handleSlidePlay !== 'undefined') {
      handleSlidePlay()
    }
  }

  pathFromUrl(url) {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b/gm

    if (regex.test(url)) {
      return url.replace(regex, '')
    }

    return url
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            menus: wordpressMenusMenusItems(slug: {eq: "menu-principal"}) {
              items {
                db_id
                title
                url
              }
            }
          }
        `}
        render={data => (
          <div className="menu" ref={this.menuRef}>
            <button
              aria-expanded="false"
              aria-label="Afficher le menu"
              className="menu__trigger"
              onClick={this.toggleMenu}
              type="button"
            >
              <span />
            </button>
            <div className="menu__wrapper" aria-hidden="true">
              <div className="menu__holder">
                <ul>
                  {data.menus.items.map(item => (
                      <li key={item.db_id}>
                        <Link to={this.pathFromUrl(item.url)} onClick={this.closeMenu.bind(this)}>{item.title}</Link>
                      </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      >

      </StaticQuery>
    )
  }
}

Menu.propTypes = {
  handleSlidePause: PropTypes.func,
  handleSlidePlay: PropTypes.func,
}

export default Menu
